.divider {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem 0;
	&.is-vertical {
		flex-direction: column;
		.line {
			width: 2px;
			height: unset;
		}
	}
	.line {
		flex: 1;
		height: 2px;
		background: $primary;
	}
	.text {
		padding: 1rem;
		color: $primary;
		font-size: 1.2rem;
	}
}
