@use 'sass:math';

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
		  dash $duration ease-in-out infinite,
		  colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
  0% { stroke: $primary; }
  25% { stroke: $grey-dark; }
  50% { stroke: $primary; }
  75% { stroke: $grey-darker; }
  100% { stroke: $primary; }
}

@keyframes dash {
  0% { stroke-dashoffset: $offset; }
  50% {
	stroke-dashoffset: math.div($offset,4);
	transform:rotate(135deg);
  }
  100% {
	stroke-dashoffset: $offset;
	transform:rotate(450deg);
  }
}
