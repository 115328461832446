$primary: #faa21b;
$grey-lighter: #dbdee0;
$grey-light: #bfbfbf;
$grey: darken($grey-light, 18);
$grey-dark: darken($grey, 18);
$grey-darker: darken($grey, 40);

$orange: #e67e22;
$yellow: #f1b70e;
$green: #2ecc71;
$turquoise: #1abc9c;
$blue: #3498db;
$purple: #8e44ad;
$red: #e74c3c;
$white-ter: #ecf0f1;
$yellow-invert: #fff;

$family-sans-serif: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-monospace: "Inconsolata", "Consolas", "Monaco", monospace;

$radius-small: 3px;
$radius: 0.4em;
$radius-large: 8px;
$size-7: 0.8rem;
$title-weight: 500;
$subtitle-weight: 400;
$subtitle-color: $grey-dark;

$border-width: 1px;
$border: $grey;

$body-background-color: darken($grey-darker, 4);
$body-size: 15px;

$background: $grey-darker;
$footer-background-color: $background;
$button-background-color: $background;
$button-border-color: lighten($button-background-color, 15);

$title-color: #fff;
$subtitle-color: $grey-light;
$subtitle-strong-color: $grey-light;

$text: $white-ter;
$text-light: lighten($text, 10);
$text-strong: darken($text, 5);

$box-color: $text;
$box-background-color: $grey-dark;
$box-background-secondary-color: lighten($grey-dark, 5%);
$box-shadow: none;

$link: $text;
$link-hover: lighten($link, 5);
$link-focus: darken($link, 5);
$link-active: darken($link, 5);
$link-focus-border: $grey-light;

$button-color: $primary;
$button-hover-color: darken($text, 5); // text-dark
$button-focus: darken($text, 5); // text-dark
$button-active-color: darken($text, 5); // text-dark
$button-disabled-background-color: $grey-light;

$input-hover-color: $grey-light;
$input-disabled-background-color: $grey-light;
$input-disabled-border: $grey-lighter;

$table-color: $text;
$table-head: $grey-lighter;
$table-background-color: $grey-dark;
$table-cell-border: 1px solid $grey;

$table-row-hover-background-color: $grey-darker;
$table-striped-row-even-background-color: $grey-darker;
$table-striped-row-even-hover-background-color: lighten($grey-darker, 2);

$pagination-color: $link;
$pagination-border-color: $border;

$navbar-height: 4rem;

$navbar-background-color: $primary;
$navbar-item-color: $text;
$navbar-item-hover-color: $link;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-color: $link;
$navbar-dropdown-arrow: #fff;
$navbar-divider-background-color: rgba(0, 0, 0, 0.2);
$navbar-dropdown-border-top: 1px solid $navbar-divider-background-color;
$navbar-dropdown-background-color: $primary;
$navbar-dropdown-item-hover-color: $grey-lighter;
$navbar-dropdown-item-hover-background-color: transparent;
$navbar-dropdown-item-active-background-color: transparent;
$navbar-dropdown-item-active-color: $link;
$navtools-border-bottom: 1px solid $primary;

$dropdown-content-background-color: $background;
$dropdown-item-color: $text;

$progress-value-background-color: $grey-lighter;

$box-background-color: rgba(84, 84, 84, 0.7);
$box-border-color: lighten($box-background-color, 20%);

$bulmaswatch-import-font: true !default;
$viz-panel-bg: rgba(0, 0, 0, 0.8);
$viz-panel-half-bg: rgba(0, 0, 0, 0.8);
$logocolour: $primary;
$logocolourhover: rgba(255, 255, 255, 0.7);

$input-border-color: rgba($box-background-color, 0.2);
$input-background-color: rgba($grey-dark, 0.2);
$input-color: $text;
$input-focus-border-color: $primary;

$menu-background-color: rgba($grey-dark, 1.0);

$switch-background: #252525;
$tabs-toggle-link-active-background-color: $primary;

$tag-color: black;

$card-container-border-color: $grey;