$viewer-controls-width: 410px;
.viewer-controls {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	padding: 8px 8px 100px 8px;
	width: $viewer-controls-width;
	height: 100%;
	background: transparent;
	flex-direction: column;
	pointer-events: none;
	z-index: 5 !important;

	.expand-or-hide {
		cursor: pointer;
		background: rgba($box-background-secondary-color, 0.6);
		padding: 4px;
		&:hover {
			background: rgba($box-background-secondary-color, 0.8);
		}
		> svg {
			margin: 0 5px;
		}
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}
	&.controls-expanded {
		position: relative;
		transition: all .5s ease-in-out;
		background: $box-background-color;
		padding: 8px;
		
		.con.sidebar-content {
			background: transparent;
			border-radius: 0;
			flex: 1;
		}
		.sidebar-content {
			flex: 1;
			width: 390px;
		}
	}
	> * {
		pointer-events: all;
	}

	.sidebar-content {
		display: flex;
		flex-direction: column;
		background: $box-background-color;
		border-radius: 4px;
		overflow-y: auto;
		.spacer {
			flex: 1;
		}

		#minimap-container {
			height: 200px;
			display: inline-block;
		}

		nav.tools {
			margin-top: 1em;
			display: flex;
			min-height: 35px;
			border-bottom: $navtools-border-bottom;

			> * {
				min-height: 30px;
				text-align: center;
				align-items: center;
				flex: 1;
				border-radius: 0;
			}
		}

		.tools-output {
			padding: 20px;
			overflow-y: auto;
		}
		&.controls-minimised {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.site-selector-button {
		color: $text;
		border: 1px solid rgba(255, 255, 255, 0.2);
		padding: 0;
		display: flex;
		height: 45px;

		.home-button {
			width: 45px;
			&:hover {
				color: $primary;
			}
			svg {
				fill: currentColor;
			}
		}

		.site-selector-sidebar-button {
			width: 40px;
			display: flex;
			a {
				flex: 1;
			}
		}

		.site-name {
			flex: 1;
			border-color: rgba(255, 255, 255, 0.3);
			border-style: dotted;
			border-width: 0 1px 0 1px;
			justify-content: flex-start;
			padding: 0;
			overflow: hidden;
			.site-actual-name {
				margin-right: 5px;
			}

			&:hover {
				background: rgba(255, 255, 255, 0.3);
				cursor: pointer;
				color: $primary;
			}
		}

		> * > .button {
			height: 100%;
			width: 100%;
			flex: 1;
		}

		> * {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.is-divider-vertical {
			padding: 2rem 1rem;
		}

		.home-button {
			text-align: center;
			color: $grey-light;

			&:hover {
				color: $primary;
			}
		}
	}
	.viewer-controls-footer {
		.visualize-brand-container {
			display: flex;
			justify-content: center;
			padding: 10px;
		}
	}
}
