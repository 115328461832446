$primary: $turquoise !default;

$cyan: #0fc5d9;
$info: $cyan !default;
$success: $green !default;
$warning: $yellow !default;
$danger: $red !default;

$white-ter: #ecf0f1;
$light: $white-ter !default;
$dark: $grey-darker !default;

// Invert colors

$yellow: #f1b70e;
$turquoise: #1abc9c;
$orange-invert: findColorInvert($orange) !default;
$yellow-invert: findColorInvert($yellow) !default;
$green-invert: findColorInvert($green) !default;
$turquoise-invert: findColorInvert($turquoise) !default;
$cyan-invert: findColorInvert($cyan) !default;
$blue-invert: findColorInvert($blue) !default;
$purple-invert: findColorInvert($purple) !default;
$red-invert: findColorInvert($red) !default;

$primary-invert: $turquoise-invert !default;
$info-invert: $cyan-invert !default;
$success-invert: $green-invert !default;
$warning-invert: $yellow-invert !default;
$danger-invert: $red-invert !default;
$light-invert: $dark !default;
$dark-invert: $light !default;

// General colors

$background: $white-ter !default;

$border: $grey-lighter !default;
$border-hover: $grey-light !default;

// Text colors

$text: $grey-dark !default;
$text-invert: findColorInvert($text) !default;
$text-light: $grey !default;
$text-strong: $grey-darker !default;

// Code colors

$code: $red !default;
$code-background: $background !default;

$pre: $text !default;
$pre-background: $background !default;

// Link colors

$link: $blue !default;
$link-invert: $blue-invert !default;
$link-visited: $purple !default;

$link-hover: $grey-darker !default;
$link-hover-border: $grey-light !default;

$link-focus: $grey-darker !default;
$link-focus-border: $blue !default;

$link-active: $grey-darker !default;
$link-active-border: $grey-dark !default;

$title-link-color: $link !default;
$title-link-hover-color: $link-hover !default;
$title-link-active-color: $link-active !default;
$title-link-focus-color: $link-focus !default;

$subtitle-link-color: $link !default;
$subtitle-link-hover-color: $link-hover !default;
$subtitle-link-active-color: $link-active !default;
$subtitle-link-focus-color: $link-focus !default;

// Typography
$family-monospace: "Inconsolata", "Consolas", "Monaco", monospace;

$family-primary: $family-sans-serif !default;
$family-secondary: $family-sans-serif !default;
$family-code: $family-monospace !default;

$size-small: 0.8rem !default;
$size-normal: 1rem !default !default;
$size-medium: 1.25rem !default;
$size-large: 1.5rem !default;

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;


//$colors: mergeColorMaps(("white": ($white, $black), "black": ($black, $white), "light": ($light, $light-invert), "dark": ($dark, $dark-invert), "primary": ($primary, $primary-invert), "link": ($link, $link-invert), "info": ($info, $info-invert), "success": ($success, $success-invert), "warning": ($warning, $warning-invert), "danger": ($danger, $danger-invert)), $custom-colors) !default;
//$shades: mergeColorMaps(("black-bis": $black-bis, "black-ter": $black-ter, "grey-darker": $grey-darker, "grey-dark": $grey-dark, "grey": $grey, "grey-light": $grey-light, "grey-lighter": $grey-lighter, "white-ter": $white-ter, "white-bis": $white-bis), $custom-shades) !default;

//$size-1: 3rem !default$size-2: 2.5rem !default$size-3: 2rem !default$size-4: 1.5rem !default$size-5: 1.25rem !default$size-6: 1rem !default$size-7: 0.75rem !default$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 !default;

// switch
$switch-background: $grey-light !default;
$switch-border: 0.1rem solid transparent !default;
$switch-background-active: $primary !default;
$switch-radius: $radius !default;
$white: #ffffff;
$switch-paddle-background: $white !default;
$switch-paddle-background-active: $primary !default;
$switch-paddle-offset: 0.25rem !default;
$switch-paddle-transition: all 0.25s ease-out !default;
$switch-focus: 1px dotted $grey-light !default;
// divider
$divider-background-color: $grey-lighter !default;
$divider-background-box-color: $white !default;
$divider-thickness: 0.1rem !default;
$divider-font-size: 0.8rem !default;
$divider-color: $grey-light !default;

// slider
$slider-radius: $radius !default;
$slider-track-background: $grey-lighter !default;
$slider-track-radius: $radius !default;
$slider-track-border: 0px solid $grey !default;
$slider-track-shadow: 0px 0px 0px $grey !default;
$slider-thumb-background: $white !default;
$slider-thumb-radius: $radius !default;
$slider-thumb-border: 1px solid $grey-light !default;
$slider-thumb-shadow: none !default;
$slider-output-width: 3rem !default;
$slider-output-background: $grey-dark !default;
$slider-output-radius: $radius !default;

// ribbon
$ribbon-color: $grey-darker !default;
$ribbon-background-color: $white !default;
$ribbon-border: 0.1rem solid $grey-lighter !default;

//accodion variables

$accordion-background-color: $background !default;
$accordion-radius: $radius !default;

$accordion-header-background-color: $text !default;
$accordion-header-color: $text-invert !default;
$accordion-header-padding: 0.5em 0.75em !default;
$accordion-header-radius: $radius !default;



$accordion-content-border: 1px solid $border !default;
$accordion-content-color: $text !default;

$accordion-content-padding-top: 1em !default;
$accordion-content-padding-left: 1.25em !default;
$accordion-content-radius: $radius !default;

$accordion-content-pre-background-color: $white !default;
$accordion-content-pre-code-background-color: transparent !default;
