.login-logo-container {
  padding: 20px 30px;

  svg {
    width: 100%;
    max-width: 400px;
    color: "black";
  }
}

%login-error {
  width: 100%;
  text-align: center;
  border: 1px solid #f5c6cb;
  color: #721c24;
  background-color: #f8d7da;
  clear: both;
}

.login-form {
  box-sizing: border-box;
  min-width: 250px;

  input {
    width: 100%;
    background-color: #fff8c5;
    border-color: #ffe5b7;
  }

  .control {
    width: 100%;
  }

  .login-error {
    @extend %login-error
  }
}


.forgot-password-error {
  @extend %login-error
}

.forgot-password-success {
  @extend %login-error;
  background-color: #00ff00;
}

.forgot-password-loading .loading {
  padding-left: 45%;
}

.sso-container {
  display: flex;
  align-items: center;

  .sso-button {
    margin: 0 auto;
    background-image: url('/assets/img/sso_button_large.svg');
    height: 44px;
    width: 215px;
    border-radius: 7px;
    border-width: 1px;
    border: 1.5px solid black;
  }

  .sso-button:hover {
    border: 1.5px solid orange;
  }
}
