$pgColumnPaddingTop: 10px;
$pgColumnPaddingBottom: 5px;
$pgValuePaddingTop: 7px;

//why pointgrab tabs has margin-bottom of 21px?
//here we offset it by negative value
.offset-tab-margin-bottom {
  margin-top: -21px;
}

.pointgrab-tool {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  min-width: 200px;
}

.pgError {
  padding: 10px;
}

.pgformVolumeTitle {
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-weight: 700;
  font-size: 14px;
  margin-top: 10px;
}

.pgformVolumeValue {
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  margin-bottom: 20px;
}

.pgformButtons {
  margin: 20px 0;
  display: flex;
  width: 100%;
  justify-content: space-around;

  .button {
    &.clear-selection {
      width: 130px;
    }

    &.export {
      width: 100px;
    }
  }
}

.pointgrab-tool .column-heading {
  width: 40px;
  margin-left: auto;
  margin-right: auto;

  span {
    font-size: 10px;
    font-weight: 100;
    text-transform: uppercase;
  }

  // labels column
  .column1 {
    flex-shrink: 1;
    padding: 0;
  }
}


.pointgrab-tool .column1 .pgcontainer {
  display: flex;
  flex-direction: column;
  padding-top: 25px;

  div {
    height: 35px;
  }

}


// size and rotation column
.pointgrab-tool .column2 {
  width: 150px;
  padding: 0 5px 10px 5px;
  text-align: center;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;

  .pgcontainer {
    display: flex;
    flex-direction: column;
    border: 1px dotted;
    padding-top: $pgColumnPaddingTop;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: $pgColumnPaddingBottom;

    .row {
      min-width: 95px;
      max-width: 110px;
      margin-left: auto;
      margin-right: auto;
      height: 35px;
      display: flex;
      justify-content: space-between;
    }
  }

  .rotation-container {
    min-width: 95px;
    max-width: 110px;
    margin-left: 9px;
    margin-right: auto;
    height: 35px;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
  }

  .button {
    @extend %button-hover;
  }
}


.rotation-value,
.translation-value {
  min-width: 40px;
  width: auto;
  padding: $pgValuePaddingTop 3px 0px 3px;
}

.rotation-value {
  padding-left: 0px;
}

%button-hover {
  &:hover {
    color: $primary;
    border-color: $primary;
  }
}

// move column
.pointgrab-tool .column3 {
  width: 120px;
  order: 2;
  padding: 0 5px 10px 5px;
  text-align: center;

  .pgcontainer {
    display: flex;
    flex-direction: column;
    border: 1px dotted;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: $pgColumnPaddingTop;
    padding-bottom: $pgColumnPaddingBottom;
    min-width: 70px;

    .row {
      flex-shrink: 1;
      min-width: 45px;
      max-width: 75px;
      height: 35px;
      margin-left: auto;
      margin-right: auto;
    }

    .button {
      @extend %button-hover;
    }
  }
}


.pointgrab-tool-button-container {
  flex-shrink: 1;
  width: 260px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}


.pointgrab-density-select-container {
  min-width: 150px;
  width: 100%;
}

.pointgrab-density-select-container .pgd__single-value {
  color: $text;
}

.pointgrab-density-select-container .pgd__control {
  color: $text;
  background: $input-background-color;
}

.pointgrab-density-select-container .pgd__control--is-focused:hover {
  border-color: $primary;
}

.pointgrab-density-select-container .pgd__control--is-focused {
  border-color: $primary;
  box-shadow: 0 0 0 1px $primary;
}

.pointgrab-downloads {
  max-height: 55vh;
  overflow-y: auto;
}

@media screen and (max-height: 1080px) {
  .pointgrab-downloads {
    max-height: 38vh;
  }
}

.pointgrab-downloads-row {
  padding-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .file-format {
    border: 1px solid;
    margin: 3px 10px 3px 8px;
    padding: 4px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    height: 1.5rem;
    border-radius: 5px;
  }

  .icon {
    &:hover {
      color: $primary;
      border-color: $primary;
    }
  }

  &:nth-child(even) {
    background: rgba(255, 255, 255, 0.1);
  }
}

.pointgrab-delete-footer {
  margin-top: 10px;
  float: right;

  button {
    margin-left: 10px;
  }
}

::selection {
  background-color: lighten($primary, 30);
}

.download-file-format-select-container {
  display: flex;
  padding-bottom: 5px;
  margin: 0 8px;

  .format {
    display: flex;
    align-items: center;
    padding-right: 15px;
    margin-top: 10px;

    label {
      margin-left: 3px;
    }
  }
}

.export-pointcloud {
  padding-top: 10px;
}

.export-pointcloud input {
  color: #fff;
  font-size: 18px;
  width: 100%;
}

.export-pointcloud label {
  color: #fff;
  font-size: 18px;
}

.export-pointcloud select {
  color: #fff;
  font-size: 14px;
}

.export-pointcloud svg {
  color: #fff;
  font-size: 18px;
}

.css-a88p61-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid #fff !important;
}

#modal-element-external {
  background-color: $grey-darker;
}

#modal-element-external .pgd__menu {
  background-color: $input-background-color;
}

#modal-element-external .pgd__option {
  background-color: $input-background-color;
}

#modal-element-external .pgd__option:hover {
  background-color: rgba($primary, 0.5);
}

#modal-element-external .pgd__menu-list {
  color: $text;
  background-color: $menu-background-color;
}

#external .pgd__menu {
  background-color: $input-background-color;
  z-index: 100;
}

#external .pgd__option {
  background-color: $input-background-color;
}

#external .pgd__option:hover {
  background-color: rgba($primary, 0.5);
}

#external .pgd__menu-list {
  color: $text;
  background-color: $menu-background-color;
  z-index: 100;

}
