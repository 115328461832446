$pageloader-opacity: 1 !default;

// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-o-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

.pageloader {
  //@include overlay;

  position: fixed;
  padding-top: 0;
  background: $primary;
  background: rgba($primary, $pageloader-opacity);

  //&.is-success {
  //  &::after {
  //
  //  }
  //  div.success-checkmark {
  //    left: 50%;
  //    top: 50%;
  //    position: absolute;
  //    transform: translate(-50%, -50%);
  //    margin-top: -3em;
  //  }
  //}

  //@each $name, $pair in $colors {
  //  $color: nth($pair, 1);
  //  $color-invert: nth($pair, 2);
  //
  //  &.is-#{$name} {
  //    background-color: $color;
  //    background: rgba($color, $pageloader-opacity);
  //    transition: background-color 1000ms linear;
  //
  //    div.pageloader-result {
  //      left: 50%;
  //      top: 50%;
  //      position: absolute;
  //      transform: translate(-50%, -50%);
  //      margin-top: -3em;
  //    }
  //
  //    &::after {
  //      display: none;
  //      border-color: $color-invert;
  //      -webkit-animation: loader-figure-#{$name} 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  //      -moz-animation: loader-figure-#{$name} 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  //      animation: loader-figure-#{$name} 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  //    }
  //
  //    .title {
  //      color: $color-invert;
  //    }
  //
  //    svg {
  //      width: 5em;
  //      height: 5em;
  //      margin-top: -4em;
  //      border-radius: 50%;
  //      display: block;
  //      stroke-width: 2;
  //      stroke: $light;
  //      stroke-miterlimit: 10;
  //      box-shadow: inset 0px 0px 0px $light;
  //
  //      .cross__path {
  //        stroke-dasharray: 1000;
  //        stroke-dashoffset: 0;
  //        stroke:$light;
  //        stroke-width:6;
  //        stroke-linecap:round;
  //        stroke-miterlimit:10;
  //        &.cross__circle {
  //          -webkit-animation: dash .9s ease-in-out;
  //          animation: dash .9s ease-in-out;
  //        }
  //        &.cross__line {
  //          stroke-dashoffset: 1000;
  //          -webkit-animation: dash .9s .35s ease-in-out forwards;
  //          animation: dash .9s .35s ease-in-out forwards;
  //        }
  //      }
  //
  //    }
  //  }
  //}

  z-index: 999998;
  transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
  will-change: transform;

  &:not(.is-left-to-right),
  &:not(.is-right-to-left) {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  &.is-left-to-right {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  &.is-right-to-left {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  &.is-active {
    &:not(.is-left-to-right),
    &:not(.is-right-to-left) {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }

    &.is-left-to-right,
    &.is-right-to-left {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    border-radius: 100%;
    content: "";
    z-index: 9999;
    margin-top: -5em;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 0 solid $white;
    -webkit-animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: 0;
    font-size: 0.875em;
    letter-spacing: 0.1em;
    line-height: 1.5em;
    color: $white;
    white-space: nowrap;
  }

  @include keyframes(loader-figure) {
    0% {
      height: 0;
      width: 0;
      background-color: $white;
    }

    29% {
      background-color: $white;
    }

    30% {
      height: 5em;
      width: 5em;
      background-color: transparent;
      border-width: 2.5em;
      opacity: 1;
    }

    100% {
      height: 5em;
      width: 5em;
      border-width: 0;
      opacity: 0;
      background-color: transparent;
    }
  }
}

//@each $name, $pair in $colors {
//  $color: nth($pair, 1);
//  $color-invert: nth($pair, 2);
//
//  @include keyframes(loader-figure-#{$name}) {
//    0% {
//      height: 0;
//      width: 0;
//      background-color: $color-invert;
//    }
//
//    29% {
//      background-color: $color-invert;
//    }
//
//    30% {
//      height: 5em;
//      width: 5em;
//      background-color: transparent;
//      border-width: 1em;
//      opacity: 1;
//    }
//
//    100% {
//      height: 5em;
//      width: 5em;
//      border-width: 0;
//      opacity: 0;
//      background-color: transparent;
//    }
//  }
//}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $light;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}


@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
