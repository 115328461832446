$sidebar-width: 300px !default;
.sidebar {
	background: transparent;
	position: fixed;
	top: 0;
	left: 0;
	transition: all 300ms;
	width: 100%;
	max-width: 100%;
	height: 100%;
	display: flex;
	&.panel-active {
		.close-sidebar {
			position: absolute;
			right: 10px;
			top: 10px;
		}
		background: $box-background-color;
	}

	.sidebar-panel {
		flex: 1;
		display: flex;
	}

	&.active {
		.sidebar-inner {
			transform: translateX(0);
		}
	}
	.sidebar-content {
		background: $box-background-color;
		transition: all 300ms ease-in-out;
		overflow-y: auto;
		padding: 5px;
		overflow-x: hidden;
	}
	.sidebar-inner {
		border-right: 1px solid $box-border-color;
		flex: 0 0 360px;

		transform: translateX(-100%);
		background: $box-background-color;
		transition: all 300ms ease-in-out;
		padding: 10px;
		display: flex;
		flex-direction: column;

		.logo-container {
			padding: 20px 0;

			.logo a {
				color: $primary;
			}
		}

		.is-divider {
			margin: 1rem 0;
		}

		.sidebar-header {
			display: flex;
			flex-direction: row;
			align-items: center;

			.logo {
				min-width: 230px;
				color: $primary;
				flex: 1;
				padding: 5px 20px 0 20px;
			}

			.collapse-sidebar {
				width: 90px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}

		.panel-block {
			border: none;
			color: $text;

			&:hover {
				color: $primary;
			}
		}

		.panel-heading {
			margin-top: 5px;
			border: none;
			color: $primary;
		}

		a.panel-block:hover {
			text-decoration: none;
			font-weight: 600;
		}
	}
}

.sidebar.sites-sidebar {
	.sidebar-content {
		background: transparent;
	}
	.site-selector {
		transition: all 400ms ease 0s;
		padding: 20px;

		&.loading {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.ss-site {
			width: 400px;
			cursor: pointer;
			border: 2px solid rgba($primary, 0.5);

			&:hover {
				.title {
					color: $white;
				}

				color: $white;
				background: $primary;
				border: 2px solid $box-background-color;
			}
		}
	}
}

.sidebar.sidebar-anim-exit-active {
	.sidebar-inner {
		transform: translateX(-100%);
	}

	&.panel-active {
		background: transparent;
		opacity: 0;
	}
}

.cards-container-portal {
	max-height: 85vh;
	overflow-y: auto;

	transition: all 0.5s ease-in-out;
	.card-container {
		margin: 10px;
		border: 1px solid;
		border-color: $card-container-border-color;
	}

	.one-card {
		max-height: 85vh;
	}

	.two-cards {
		max-height: 75vh;
	}
}

@media screen and (max-height: 1080px) {
	.cards-container-portal {
		.one-card {
			max-height: 85vh;
		}

		.two-cards {
			max-height: 70vh;
		}

		.three-cards {
			max-height: 60vh;
		}
	}
}