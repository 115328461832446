.field-body {
	> .field {
		&.has-addons {
			> .control:last-child {
				> .button {
					height: 100%;
				}
			}
		}
	}
}
.navbar {
	> .container {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}
}
a {
	> .title {
		color: $title-link-color;
		&:hover {
			color: $title-link-hover-color;
		}
		&:active {
			color: $title-link-active-color;
		}
		&:focus {
			color: $title-link-focus-color;
		}
	}
	> .subtitle {
		color: $subtitle-link-color;
		&:hover {
			color: $subtitle-link-hover-color;
		}
		&:active {
			color: $subtitle-link-active-color;
		}
		&:focus {
			color: $subtitle-link-focus-color;
		}
	}
}

.control.has-icons-left .icon.password-icon,
.control.has-icons-right .icon.password-icon {
	pointer-events: all;
	cursor: pointer;
}

.password-icon.icon.is-mouse-over {
	color: $grey-dark;
}
