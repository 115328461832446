.help-button {
	position: fixed;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	right: -93px;
	bottom: 300px;
	z-index: 100;
	background: $box-background-color;
	border-radius: 10px 0 0 10px;
	padding-right: 10px;
	transition: all 0.25s ease;

	svg {
		height: 40px;
		width: 40px;
		fill: $primary;
		margin: 5px;
	}

	a {
		padding: 0 10px;
	}

	&:hover {
		right: 0px;
	}
}

.feedback-button {
	position: fixed;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	right: -93px;
	bottom: 40px;
	z-index: 100;
	background: $box-background-color;
	border-radius: 10px 0 0 10px;
	padding-right: 10px;
	transition: all 0.25s ease;

	svg {
		height: 40px;
		width: 40px;
		fill: $primary;
		margin: 5px;
	}

	a {
		padding: 0 10px;
	}

	&:hover {
		right: 0px;
	}
}

.help-button {
	bottom: 97px;
	right: -60px;
	color: $primary;

	.help-button-icon {
		margin-left: 10px;
	}

}

.screenshot-preview {
	position: relative;
	padding: 10px;
	border: 2px solid $primary;
	border-radius: 5px;

	.delete {
		position: absolute;
		top: 15px;
		right: 15px;
	}
}

.screenshot-note {
	font-size: 11px;
	margin-top: 10px;
}

.close-selector-alert {
	left: 450px;
}

.close-feedback-form {
	left: 450px;
}

.buttons-feedback-form {
	svg {
		height: 20px;
		width: 20px;
		fill: $white;
		margin: 5px;
	}
}
