// Overrides
hr {
	height: $border-width;
}

h6 {
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

.hero {
	background-color: $grey-dark;
}

a {
	transition: all 200ms ease;
}

.button {
	transition: all 300ms ease;
	border-width: 1px;
	color: $white;


	&.is-active,
	&.is-focused,
	&:active,
	&:focus {
		box-shadow: none;
		box-shadow: 0 0 0 2px rgba($primary, 0.5);
	}
	&.is-disabled {
		opacity: 0.5;
	}
	// @each $name, $pair in $colors {
	// 	$color: nth($pair, 1);
	// 	$color-invert: nth($pair, 2);

	// 	&.is-#{$name} {
	// 		&.is-hovered,
	// 		&:hover {
	// 			background-color: lighten($color, 7.5%);
	// 		}

	// 		&.is-active,
	// 		&.is-focused,
	// 		&:active,
	// 		&:focus {
	// 			border-color: $color;
	// 			//box-shadow: 0 0 0 2px rgba($color, 0.5);
	// 			box-shadow: none;
	// 		}
	// 	}
	// }
}

.label {
	color: $grey-lighter;
}

.button,
.control.has-icons-left .icon,
.control.has-icons-right .icon,
.input,
.pagination-ellipsis,
.pagination-link,
.pagination-next,
.pagination-previous,
.select,
.select select,
.textarea {
	height: 2.5em;
}

.input,
.textarea {
	transition: all 200ms ease;
	box-shadow: none;
	border-width: $border-width;
	padding-left: 1em;
	padding-right: 1em;
}

.select {
	&:after,
	select {
		border-width: $border-width;
	}
}

.control {
	&.has-addons {
		.button,
		.input,
		.select {
			margin-right: -$border-width;
		}
	}
}

.notification {
	background-color: $grey-dark;
}

.card {
	$card-border-color: lighten($grey-darker, 5);
    $black-bis: #000000;
	box-shadow: none;
	border: $border-width solid $card-border-color;
	background-color: $grey-darker;
	border-radius: $radius;

	.card-image {
		img {
			border-radius: $radius $radius 0 0;
		}
	}

	.card-header {
		box-shadow: none;
		background-color: rgba($black-bis, 0.2);
		border-radius: $radius $radius 0 0;
	}

	.card-footer {
		background-color: rgba($black-bis, 0.2);
	}

	.card-footer,
	.card-footer-item {
		border-width: $border-width;
		border-color: $card-border-color;
	}
}

.notification {
	// @each $name, $pair in $colors {
	// 	$color: nth($pair, 1);
	// 	$color-invert: nth($pair, 2);

	// 	&.is-#{$name} {
	// 		a:not(.button) {
	// 			color: $color-invert;
	// 			text-decoration: underline;
	// 		}
	// 	}
	// }
}

.tag {
	border-radius: $radius;
}

.menu-list {
	a {
		transition: all 300ms ease;
	}
}

.modal-card-body {
	background-color: $grey-darker;
}

.modal-card-foot,
.modal-card-head {
	border-color: $grey-dark;
}

.modal-card-title.align-center {
	text-align: center;
}

.message-header {
	font-weight: bold;
	background-color: $grey-dark;
	color: $white;
}

.message-body {
	border-width: $border-width;
	border-color: $grey-dark;
}

.navbar {
	border-radius: $radius;

	&.is-transparent {
		background: none;
	}

	&.is-primary {
		.navbar-dropdown {
			a.navbar-item.is-active {
				background-color: $link;
			}
		}
	}

	// @include touch {
	// 	.navbar-menu {
	// 		background-color: $navbar-background-color;
	// 		border-radius: 0 0 $radius $radius;
	// 	}
	// }
}

.hero .navbar,
body > .navbar {
	border-radius: 0;
}

.pagination-link,
.pagination-next,
.pagination-previous {
	border-width: $border-width;
}

.panel-block,
.panel-heading,
.panel-tabs {
	border-width: $border-width;

	&:first-child {
		border-top-width: $border-width;
	}
}

.panel-heading {
	font-weight: bold;
}

.panel-tabs {
	a {
		border-width: $border-width;
		margin-bottom: -$border-width;

		&.is-active {
			border-bottom-color: $link-active;
		}
	}
}

.tabs {
	a {
		border-bottom-width: $border-width;
		margin-bottom: -$border-width;
	}

	ul {
		border-bottom-width: $border-width;
	}

	&.is-boxed {
		a {
			border-width: $border-width;
		}

		li.is-active a {
			background-color: darken($grey-darker, 4);
		}
	}

	&.is-toggle {
		li a {
			border-width: $border-width;
			margin-bottom: 0;
		}

		li + li {
			margin-left: -$border-width;
		}
	}
}

.hero {
	// Colors
	// @each $name, $pair in $colors {
	// 	$color: nth($pair, 1);
	// 	$color-invert: nth($pair, 2);

	// 	&.is-#{$name} {
	// 		.navbar {
	// 			.navbar-dropdown {
	// 				.navbar-item:hover {
	// 					background-color: $navbar-dropdown-item-hover-background-color;
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}
.button.is-link {
	background-color: transparent;
	border-color: transparent;
	color: $primary;
}
.dropdown .dropdown-menu .dropdown-item:hover {
	background: $primary;
	color: $white;
}
.button.is-text.is-active {
	border: 1px solid $primary;
}

.panel-heading {
	border-radius: 2px 2px 0 0;
}

html,
body {
	color: $text;
}

::-webkit-scrollbar {
	width: 12px;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: rgba(250, 162, 27, 0.8);
}

.panel-block {
	&:hover {
		color: $link-hover;

		.panel-icon {
			color: $link-hover;
		}
	}

	&.is-active {
		.panel-icon {
			color: $link-active;
		}
	}
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
	border: 1px solid rgba(99, 99, 99, 0.2);
}

body .ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button,
body .ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button,
body .ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button,
body .ql-snow.ql-toolbar .ql-picker-label,
.ql-snow .ql-toolbar .ql-picker-label,
body .ql-snow.ql-toolbar .ql-picker-label,
.ql-snow .ql-toolbar .ql-picker-label,
body .ql-snow.ql-toolbar .ql-picker-item,
.ql-snow .ql-toolbar .ql-picker-item,
body .ql-snow.ql-toolbar .ql-picker-item,
.ql-snow .ql-toolbar .ql-picker-item {
	color: $text;
}

body .ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
body .ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
body .ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
body .ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
body .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
body .ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
body .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
	color: $primary;
}

.ql-snow .ql-stroke {
	stroke: $text;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
	stroke: $primary;
}

.pagination {
	.pagination-link {
		&:hover {
			color: $primary;
			border-color: $primary;
		}
		&.is-current {
			background: transparent;
			color: $primary;
			border-color: $primary;
		}
	}
	.pagination-previous,
	.pagination-next {
		&:hover {
			background: $primary;
			border-color: $primary;
		}
	}
}

.cards-container-portal .card-container header {
	background-color: $switch-background;
}

.viewer-controls .sidebar-content > #minimap-container {
	#minimap {
		background-color: $grey-dark;
	}

	&.hidden {
		.minimap-toggle {
			background-color: $box-background-color;
			border-color: $box-border-color;
			border-width: 1px;
		}
	}

	.minimap-toggle {
		background-color: $box-background-color;
	}
}

.tabs {
	li {
		&.is-active {
			a {
				border-bottom-color: $primary;
				color: $primary;
			}
		}
	}
}

.button.is-text {
    background-color: transparent;
    border-color: transparent;
	&.is-active,
	&:hover {
		color: $primary;
		border: 1px solid $primary;
	}
}
