.dropdown {
  .dropdown-menu {
	.dropdown-item {
	  text-align: left;
	  &:hover {
		color: $primary;
		cursor: pointer;
	  }
	}
  }
  &.is-expanded {
	width: 100%;

	.dropdown-trigger {
	  width: 100%
	}

	.dropdown-menu {
	  width: 100%;
	}

	.button {
	  display: flex;
	  width: 100%;
	  justify-content: space-between
	}
  }
}