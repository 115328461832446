.external-stage {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;

	.cesium-stage-viewer {
		position: relative;
		flex: 1;
		flex-basis: calc(50% - 40px);
		overflow: hidden;

		.cesium-viewer {
			position: absolute !important;
		}
	}
}