$badge-border-size: 0.1rem !default;
$badge-border-radius: 1rem !default;

@mixin badge-size($size) {
  $badgeSize: $size;
  $leftPosition: calc(100% - ( #{$badgeSize} / 2 ));
  $topPosition: calc(0px - ( #{$badgeSize} / 2 ));

  &[data-badge]::after {
    top: $topPosition;
    left: $leftPosition;
    min-height: $badgeSize;
    min-width: $badgeSize;
  }

  &:not([data-badge=""])::after {
    font-size: $size * 0.75;
    line-height: $size * 0.5;
  }

  &[data-badge=""]::after {
    width: $badgeSize;
  }

  &.is-badge-left {
    &::after {
      left: calc(0px - ( #{$badgeSize} / 2 ));
    }
  }

  &.is-badge-bottom {
    &::after {
      top: calc(100% - ( #{$badgeSize} / 2 ));
    }
  }

  &.is-badge-bottom-left {
    &::after {
      left: calc(0px - ( #{$badgeSize} / 2 ));
      top: calc(100% - ( #{$badgeSize} / 2 ));
    }
  }
}

.badge {
  position: relative;
  white-space: nowrap;
  position: relative;

  &[data-badge]::after {
    position: absolute;
    left: 100%;
    margin: 0;
    background: $primary;
    background-clip: padding-box;
    border-radius: $badge-border-radius;
    box-shadow: 0 0 0 $badge-border-size $white;
    color: $white;
    content: attr(data-badge);
    display: inline-block;
  }

  &:not([data-badge=""])::after {
    padding: 0.3rem 0.5rem;
    text-align: center;
    white-space: nowrap;
  }

  &[data-badge=""]::after {
    display: inline-block;
    vertical-align: inherit;
  }

  //@each $name, $pair in $colors {
  //  $color: nth($pair, 1);
  //  $color-invert: nth($pair, 2);
  //
  //  &.is-badge-#{$name} {
  //    &[data-badge]::after, &:not([data-badge])::after {
  //      background: $color;
  //      color: $color-invert;
  //    }
  //  }
  //}

  &.is-badge-outlined {
    &[data-badge]::after {
      background-color: $white;
      box-shadow: none;
      border: $badge-border-size solid $primary;
      color: $primary;
    }

    //@each $name, $pair in $colors {
    //  $color: nth($pair, 1);
    //  $color-invert: nth($pair, 2);
    //
    //  &.is-badge-#{$name} {
    //    &[data-badge]::after {
    //      background: $white;
    //      box-shadow: none;
    //      border: $badge-border-size solid $color;
    //      color: $color;
    //    }
    //  }
    //}
  }

  @include badge-size($size-normal);

  &.is-badge-small {
    @include badge-size($size-small);
  }

  &.is-badge-medium {
    @include badge-size($size-medium);
  }

  &.is-badge-large {
    @include badge-size($size-large);
  }
}

// IE FIX
// On IE badge was cropped
.badge {
  &.button:not(.is-clipped) {
    overflow: visible;
  }
}
