@use 'sass:math';

.nearby.controls-hidden.nearby-images .visualize-brand {
  transform: translate(0, -30px);
  transition: transform 300ms ease-in-out;
}

.nearby.controls-hidden.nearby-images-results .visualize-brand {
  transform: translate(0, -200px);
  transition: transform 300ms ease-in-out;
}

.nearby-image {
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.split-mode-top {
    max-height: 100%;
  }

  &.split-mode-bottom {
    height: 90%;
    max-height: calc(90% - 140px);
  }

  $background-color: rgba($primary, 0.8);

  .nearby-navigate-photo {
    $size: 45px;

    position: absolute !important;

    background-color: $background-color;
    width: $size * 2;
    float: right;
    position: relative;
    right: 5.5px;

    .button {
      width: $size;
      height: $size;

    }

    top: 400px;
  }

  .nearby-navigate-rotation {
    $size: 45px;

    position: absolute !important;

    background-color: $background-color;
    width: $size * 2;
    float: right;
    right: 5.5px;

    .button {
      width: $size;
      height: $size;

      &.mirror {
        transform: scaleX(-1);
      }
    }

    top: 452px;
  }

  .nearby-phototype-selector {
    $size: 45px;
    top: 503px;

    position: absolute !important;

    background-color: $background-color;
    width: 80px;
    float: right;
    right: 7px;
    padding: 3px;
    font-size: 12px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 0px;

      span:first-child {
        display: inline-flex;
        flex: 1;
        justify-content: center;
      }
    }

    .phototype-dropdown-menu {
      overflow: hidden;
      transition: all .3s ease;
      width: 100%;
      float: right;
      margin-top: 3px;
      height: 120px;

      &.closed {
        height: 0;
      }

      .entry {
        display: flex;
        width: calc(100% - 10px);
        justify-content: flex-start;
        align-items: center;
        cursor: default;

        input {
          margin-right: 7px;
        }
      }
    }
  }

  .nearby-separator {
    margin: 3px 2px;
    border-top: 1px solid #666;
    width: calc(100% - 4px);
    height: 0px;
  }

  .nearby-actions {
    $size: 45px;

    position: absolute;
    top: 105px;
    right: 28px;
    width: 45px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: $background-color;

    $borderStyle: lighten($primary, 20) solid 1px;
    $separatorStyle: 3px solid rgba(black, 0.5);

    .button {
      font-size: 22px;
      width: $size;
      height: $size;
    }

    .split-mode-position-container {
      border-bottom: $separatorStyle;
    }

    .split-mode-position {
      width: $size;
      height: $size;
      cursor: pointer;

      $hoverColor: rgba(black, 0.7);
      $hoverBorderRadius: 4px;
      $oneThird: math.div($size, 3);
      $twoThird: math.div($size * 2, 3);

      $margin: 1px;
      @mixin commonProps {
        float: left;
        margin: $margin;
        position: absolute;
        text-align: center;

        &:hover {
          background-color: $hoverColor;
          border-radius: $hoverBorderRadius;
        }

        .initial {
          float: left;
          left: 6px;
          position: absolute;
          top: $oneThird;
        }
      }

      // margin is for hover effect, to still see some orange background on the margins
      $sizeMinusMargin: $size - 2 * $margin;
      $oneThirdMinusMargin: $oneThird - 2 * $margin;

      .top {
        left: 0;
        top: 0px;
        width: $sizeMinusMargin;
        height: $oneThirdMinusMargin;
        z-index: 1;
        @include commonProps;
      }

      .bottom {
        left: 0;
        top: $twoThird + 1px;
        width: $sizeMinusMargin;
        height: $oneThirdMinusMargin;
        z-index: 1;
        @include commonProps;
      }

      .left {
        left: 0;
        width: $oneThirdMinusMargin;
        height: $sizeMinusMargin;
        z-index: 1;
        @include commonProps;
      }

      .right {
        width: $oneThirdMinusMargin;
        left: $twoThird + 1px;
        height: $sizeMinusMargin;
        z-index: 1;
        @include commonProps;

        .initial {
          left: 5px;
        }
      }

      .full {
        left: 0;
        top: 0;
        width: $sizeMinusMargin;
        height: $sizeMinusMargin;
        z-index: 0;
        @include commonProps;

        .initial {
          border-radius: 1px;
          width: $oneThird;
          height: $oneThird;
          color: $primary;
          left: $oneThird;
          top: $oneThird;
          background-color: white;
        }
      }
    }
  }

  .photo {
    transform: scale(1);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 10px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: transform 300ms ease-out;
  }
}

.nearby-thumbnail {
  cursor: pointer;
  display: inline-block;
  margin: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;

  img {
    max-height: 116px;
  }

  &.snapshot-selected {
    border: 2px solid $primary;
  }
}

.nearby-snapshots {
  align-items: center;
  background: $box-background-color;
  margin-left: $viewer-controls-width;
  transition: all 300ms ease-in-out;

  &.full-width {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
  }

  &.strip-active-when-empty {
    padding: 10px 10px 30px 10px;
    transform: translateY(0);
  }

  &.streaming {
    .control-tag {
      > *:last-child {
        border-radius: 0;
      }

      > .loading {
        border-radius: 2px 0 0 0;
        opacity: 1;
      }
    }
  }

  .control-tag {
    transform: translateY(-100%);

    .sharing {
      padding: 0 4px;

      &.is-active {
        background: rgba($box-background-color, 1);
      }
    }

    .nearby-photos-header {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(-100%);
      padding: 0 5px;
      border-radius: 2px 0 0 0;
    }

    position: relative;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 2px 0 0 2px;

    .height-type-selector {
      min-width: 100px;
      line-height: 35px;
    }

    .camera-mode-selector {
      line-height: 35px;
    }

    .radius-input {
      background: $box-background-color;
      min-width: 120px;
      line-height: 35px;

      .slider {
        padding: 0 0px;
        width: 90px;
        margin: 0;
      }

      > div {
        min-width: 23px;
      }
    }

    > *:nth-child(2) {
      border-radius: 2px 0 0 2px;
    }

    > *:not(:first-child) {
      border-radius: 0;
    }

    > * {
      background: $box-background-color;
      height: 35px;
      min-width: 40px;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > .loading {
      opacity: 0;
      transition: all 600ms;
      padding-top: 10px;
    }
  }
}

body.controls-hidden {
  .nearby-snapshots {
    width: 100%;
    max-width: 100%;
  }
}

.snapshot-strip {
  position: relative;
  bottom: 263px;
  flex: 1;
  white-space: nowrap;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  display: flex;

  .snapshot-strip-container {
    display: flex;
    justify-content: center;
    height: 115px;
    margin-bottom: 10px;
  }

  .snapshot-strip-selector {
    $size: 45px;
    margin-bottom: 54px;
    align-items: center;
  }
}

.nearbyControls {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

#app > div.visualize > div.app-content > div.cesium-stage > div:nth-child(2) > div.nearby-image > div.nearbyControls > div > div.snapshot-strip-selector > div {
  margin-left: .3em;
  margin-right: .3em;
}


.snapshot-thumb {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin: 0 10px;
  transition: transform .2s;

  &:hover {
    transform: scale(1.5);
    cursor: pointer;
    z-index: 10;
  }

  &.selected {
    padding: 0px;
    border: 2px solid $primary;
  }

  img {
    max-height: 100px;
  }

  .snapshotIcon {
    position: absolute;
    bottom: 12px;
    right: 7px;
    padding: 0px 4px;
    border: 1px solid $primary;
    color: $primary;
    background-color: #555;
  }
}

.share-nearby {
  .snapshot-strip-container {
    > * > span {
      width: 64px;
      height: 64px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.snapshot-selector {
  position: absolute;
}

.nearby .viz-split-full-screen {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 3;
  background-color: rgba($grey, 0.6);
}


.viz-split-left-half {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 50% !important;
  height: 100% !important;
}

.viz-split-right-half {
  position: absolute;
  top: 0 !important;
  left: 50% !important;
  width: 50% !important;
  height: 100% !important;
}

.viz-split-top-half {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 50% !important;
}

.viz-split-bottom-half {
  position: absolute;
  top: 50% !important;
  left: 0 !important;
  width: 100% !important;
  height: 50% !important;
}

#nearby-image-target {
  //pass-through scroll-wheel events to canvas
  pointer-events: none;
}

#nearby-image-target-area {
  position: absolute;
  border: dashed 2px black;
  background-color: $primary;
  opacity: 0.5;
  text-align: center;
  padding-top: 10px;
  pointer-events: none
}

.pagination-nearby {
  a.pagination-link {
    background-color: rgba(black, 0.5) !important;
  }

  .pagination-next, .pagination-previous {
    background-color: rgba(black, 0.5);
  }
}

.photo-call-to-action {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nearbyLoadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;

  .loadingContainer {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .nearbyImageLoadingProgress {
    border: 1px solid rgba(255, 255, 255, 0.8);
    height: 8px;
    width: 200px;
    padding: 2px;

    opacity: 1;
    animation-name: fadeInNearbyLoading;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 0.2s;

    .innerProgress {
      background-color: rgba(255, 255, 255, 0.8);;
      height: 100%;
    }
  }
}

.nearbyToolTip {
  padding: 20px;
}

@keyframes fadeInNearbyLoading {
  0% {
    opacity: 0;
    margin-top: 20px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

.threePanosphereContainer {
  width: 100%;
  height: 100%;
}

