.navisworks-tree {
	position: relative;
	padding: 10px;
	margin: 10px;
	min-height: 400px;
	background-color: rgba(0,0,0,0.1);
	overflow-y: scroll;
	max-height: 500px;
}

.navisworks-tree-item {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	height: 21px;
	display: inline-flex;

	&:hover {
		background-color: rgba(0,0,0,0.2)
	}

	.item-icon {
		flex: 0 0 21px;
		display: inline-block;
		padding: 0 5px;
		cursor: pointer;
		height: 17px;
	}

	.item-name {
		flex: 1;
		cursor: pointer;
		width: 100%;
		display: inline-block;
		color: rgb(221, 221, 221);


		&.meta {
			color: #fff;

		}
	}

	&.selected {
		background-color: rgba(129, 77, 250, 0.8)
	}

	&.sub-selected {
		background-color: rgba(129, 77, 250, 0.1)
	}
}

.navisworks-tree-children {
	margin-left: 10px;
}

.metadata-viewer {
	display: flex;
	position: fixed;
	top: -800px;
	left: 400px;
	width: 400px;
	z-index: 5;
	min-height: 500px;
	min-height: 500px;
	border-radius: 5px;
	animation: slide-in-from-top 0.25s forwards;
	margin: 10px;
	z-index: 999;

	.tabs {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		flex: 0 0 25px;
		padding-top: 10px;

		.vertical-tab {
			background-color: rgba(44, 44, 44, 0.9);
			transform: rotate(90deg);
			line-height: 25px;
			height: 25x;
			width: 100px;
			transform-origin: 0 100%;
			margin-bottom: 80px;
			text-align: center;
			border-radius: 0 0 5px 5px;
			transition: all 0.2s ease;

			&.selected {
				background-color: rgba(84, 84, 84, 0.7);
			}

			&:hover {
				cursor: pointer;
				background-color: rgba(84, 84, 84, 0.9) !important;
			}
		}
	}

	.content {
		background-color: rgba(84, 84, 84, 0.7);
		flex: 1;
		padding: 8px;
	}
}

@keyframes slide-in-from-top {
    100% { top: 0px; }
}

.metadata-tabs {
	width: 100%;

	.tab-container {
		width: 100%;
		border-bottom: 1px solid;
		margin: 0 0 6px 0;

		span {
			padding: 4px 10px;
			border-style: solid;
			border-width: 1px 1px 0 1px;
			margin: 0 10px 0 0;

			&.selected {
				background-color: rgba(255,255,255,0.3);
			}
			&:hover {
				background-color: rgba(255,255,255,0.5);
				cursor: pointer;
			}
		}
	}

	.data-container {
		th, td {
			min-width: 90px;
			padding: 0 10px 0 0;
		}

		tr:last-child td {
			border-bottom-width: 1px;
		}
	}

}

.cad-filter-menu {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 3px 10px -3px 10px;
	
	.cad-filter-icon {
		margin: 2px;
		padding: 5px;
		border-radius: 5px;
		transition: all 0.2s ease;
		height: 35px;
		display: inline-flex;
		justify-content: center;
		align-items: center;

		img {
			width: 34px;
			height: 35px;
			padding: 5px;
		}

		input {
			margin: 0 10px;
		}

		&.selected {
			background-color: rgba(255,255,255,0.3);
		}

		&:hover {
			background-color: rgba(255,255,255,0.5);
			cursor: pointer;
		}

		&.colour-picker-icon {
			width: 35px;
			height: 35px;
			display: inline-flex;
			justify-content: center;
			align-items: center;

			.colour-block {
				display: block;
				width: 20px;
				height: 20px;
				border-radius: 3px;
			}

			&:hover .colour-picker {
				display: inline-block;				
			}
			.colour-picker {
				position: relative;
				top: 16px;
				left: -182px;
				display: none;

				.colour-picker-content {
					position: absolute;
				}
			}
		}
	}
}

.cadPickerDetail {
	position: fixed;
	background-color: rgba(84, 84, 84, 0.7);
	display: flex;
	flex-direction: column;
	padding: 10px;
	z-index: 999;
}

.react-contexify {
	z-index: 99; 
}