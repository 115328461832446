#minimap {
	z-index: 10;
	overflow: hidden;
	width: 100%;
	height: 200px;
}

#minimap-container {
	position: relative;
	width: 390px;
	height: 200px;
}

#minimap-wrapper {
	height: 100%;
	overflow: hidden;
}

.viewer-controls .sidebar-content > #minimap-container {
	position: relative;
	transition: all 0.4s ease-in-out;

	&.hidden {
		height: 0px;
		min-height: 0px;
		max-height: 0px;

		.leaflet-control-container, .leaflet-map-pane {
			display: none;
		}

		#minimap {
			background-color: $grey-dark;
		}

		.minimap-toggle {
			float: right;
			right: -4px;
			position: relative;
			height: 25px;
			width: 25px;
			border-radius: 1px;
		}
	}
	.minimap-toggle {
		height: 45px;
		width: 45px;
		border-radius: 0;
		position: absolute;
		right: 0 !important;
		bottom: 0;
		z-index: 11;
	}
}

.minimap .minimap-marker {
	background-color: $primary;
	opacity: 0.5;
	width: 10px;
	height: 10px;
	position: absolute;
	top: 10px;
	left: 10px;
}

.leaflet-control-attribution {
	display: none;
}

#minimap {
	cursor: pointer !important;
}
