.model-selector {
	&.viz-split-left-half-model-selector {
		right: calc( 50% + 15px );
		&:hover {
			transform: translateX(0);
			right: calc( 50% );
			> .model-selection {
				&.active {
					border: 2px solid $primary;
				}
			}
		}
	}

	&.viz-split-bottom-half-model-selector {
		top: calc( 50% + 10px )
	}

	&.hidden {
		display: none;
		visibility: hidden;
	}
	
	position: absolute;
	top: 10px;
	right: 15px;
	transform: translateX(calc(100% - 80px));
	display: flex;
	transition: all 333ms;
	margin-right: 10px;
	&.active,
	&:hover {
		transform: translateX(0);
		right: 0;
		> .model-selection {
			&.active {
				border: 2px solid $primary;
			}
		}
	}
	.model-selection {
		margin-left: 10px;
	}
	.model-selection {
		width: 0;
		background-color: $box-background-color;
		cursor: pointer;
		border-radius: 2px;
		border: 2px solid rgba($box-background-color, 0.7);
		transition: all 333ms;
		background-size: cover;
		width: 85px;
		height: 85px;
		display: flex;
		align-items: flex-end;
		font-weight: 800;
		text-shadow: 1px 1px 0px #000;
		color: $text;
		&:hover {
			color: $primary;
			border: 2px solid rgba($primary, 0.8);
			.model-label {
				background-color: rgba($box-background-color, 0.6);
			}
		}
		.model-label {
			padding: 4px 4px;
			background-color: rgba($box-background-color, 0.4);
			flex: 1;
			font-size: 0.8rem;
		}
	}
}

.viz-split-left-half #navigationDiv {
	position: fixed;
	top: 10px;
	right: calc( 50% + 2px );
}

.viz-split-bottom-half #navigationDiv {
	position: fixed;
	top: calc( 50% + 10px );
	right: 2px;
}

#navigationDiv {
    position: absolute;
    top: 0;
    right: 5px;
}

#distanceLegendDiv {
	display: none;
}
