.measure-toolbar {
	display: flex;
	justify-content: flex-end;
}

.target-results,
.measure-results,
.places-results,
.mocs-results {
    overflow-y: auto;
}

.target-results,
.mocs-results {
    padding-right: 5px;
}

.target-results {
    max-height: 45vh;
}

.mocs-results {
    max-height: 40vh;
}

.places-results {
    max-height: 55vh;
    .card-content {
        padding: 1.5rem;
    }
}

.measure-results {
    margin-top: 10px;
    max-height: 45vh;
}


@media screen and (max-height: 1080px) {
    .measure-results {
        max-height: 23vh;
    }
    .target-results {
        max-height: 30vh;
    }

    .places-results {
        max-height: 40vh;
    }

    .mocs-results {
        max-height: 20vh;
    }

    .minimap-off {
        .measure-results {
            max-height: 43vh;
        }
        .target-results {
            max-height: 50vh;
        }

        .places-results {
            max-height: 60vh;
        }

        .mocs-results {
            max-height: 40vh;
        }
    }
}

.measure-results {
    .measure-mode-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .point-line {
            flex: 1;
            margin: 0 10px;
            border-bottom: 2px solid $primary;
            text-align: center;
        }
    }
}

.measure-icon {
	text-align: center;
	width: 35px;
}

.measure-actions {
    display: flex;
    justify-content: right;
    align-items: center;
    div {
        flex-grow: 1;
    }
    span {
        margin: 0 0 0 5px;
    }
    .button.disabled {
        opacity: 0.35;
        cursor: default;
    }
}

.submit-container{
    text-align: right;
}

