.moc-card-line,
.moc-area-line {
	padding: 2px 0px 2px 2px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	.moc-name {
		flex: 1;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	.moc-hidden-icon {
		padding: 0px 4px;
		width: 25px;
		height: 18px;
		display: inline-block;
		text-align: center;
	}
	.tag {
		min-width: 62px;
		width: 70px;
	}
	&:hover {
		color: $primary;
	}
	&.selected {
		color: $primary;
		background: rgba(0, 0, 0, 0.3) !important;
	}
	&:nth-child(odd) {
		background: rgba(255, 255, 255, 0.1);
	}
}

.card-header-title {
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
}

.quillContainer {
	.ql-container{
		height: unset;
	}
}

.mocs-results {
	padding-right: 5px;
}

.call-to-action {
	position: fixed;
	bottom: 50px;
	right: -330px;
	background: rgba(84, 84, 84, 0.7);
	height: 100px;
	width: 330px;
	padding: 15px;
    animation: call-to-action-slide 0.3s forwards;
	z-index: 2;

	.title {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 3px;
	}
}

@keyframes call-to-action-slide {
    100% { right: 20px; }
}