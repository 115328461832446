// The ribbon sizes use mixins so they can be used at different breakpoints
@mixin ribbon-small {
	font-size: $size-small;
}

@mixin ribbon-medium {
	font-size: $size-medium;
}

@mixin ribbon-large {
	font-size: $size-large;
}

.has-ribbon {
	position: relative;
}

.ribbon.is-left-ribbon {
	right: auto;
	left: 0;
	border-left: none;
	border-right: $ribbon-border;
}

.ribbon.is-bottom-ribbon {
	top: auto;
	bottom: 0.5em;
}

.ribbon {
	background-color: $ribbon-background-color;
	border: $ribbon-border;
	border-right: none;
	color: $ribbon-color;
	font-size: $size-normal;
	justify-content: center;
  padding-left: 0.75em;
  padding-right: 0.75em;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 0.5em;
  right: 0;
  font-weight: 400;
  z-index: 2;

  //@each $name, $pair in $colors {
  //	$color: nth($pair, 1);
  //	$color-invert: nth($pair, 2);
  //
  //	&.is-#{$name}:not(.is-outlined) {
  //		background-color: $color;
  //		border-color: transparent;
  //		color: $color-invert !important;
  //	}
  //
  //	&.is-#{$name} {
  //		&.is-outlined {
  //			background-color: transparent;
  //			border-color: $color;
  //		}
  //	}
  //}

  // Sizes
  &.is-small {
    @include ribbon-small;
  }

  &.is-medium {
    @include ribbon-medium;
  }

  &.is-large {
		@include ribbon-large;
	}

	&.is-outlined {
		background-color: transparent;
	}
}
