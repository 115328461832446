// indeterminate animation will be part of the stable relase of Bulma@0.7.3
.progress {
  background: hsl(0, 0%, 93%);

  &::-webkit-progress-bar {
    background: transparent !important; // override bulma.css
  }

  &:indeterminate {
    animation: progress-indeterminate 1.5s linear infinite;
    background: hsl(0, 0%, 93%) linear-gradient(to right, $text 30%, hsl(0, 0%, 93%) 30%) top left / 150% 150% no-repeat;

    &::-moz-progress-bar {
      background: transparent !important; // override bulma.css
    }

    //@each $name, $pair in $colors {
    //  $color: nth($pair, 1);
    //  &.is-#{$name} {
    //    background: hsl(0, 0%, 93%) linear-gradient(to right, $color 30%, hsl(0, 0%, 93%) 30%) top left / 150% 150% no-repeat;
    //  }
    //}
  }
}

@keyframes progress-indeterminate {
  0% {
	background-position: 200% 0;
  }
  100% {
	background-position: -200% 0;
  }
}
