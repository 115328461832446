.places-tools-container {
    display: flex;

    #places-filter-site-container {
        width: 50px;
        padding-top: 0.5em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        label {
            margin: 0;
            padding: 0 0 0 28px;
        }
    }
}

$placeLineHeight: 48px;

.place-line {
    justify-content: space-between;
    min-height: $placeLineHeight;
    max-height: $placeLineHeight;
    display: flex;
    .actions {
        transition: 300ms all;
        display: flex;
        align-items: center;
    }
    &:hover {
        background-color: $box-background-color;
        .title {
            color: $primary;
        }
        .actions {
            opacity: 1;
        }
    }
    &:nth-child(even) {
        background: rgba(255, 255, 255, 0.1);
    }
    &:not(:last-child) {
        margin-bottom: 2px;
    }
    figure {
        align-items: center;
        justify-content: flex-start;
        display: flex;
    }
    .title {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: $placeLineHeight;
        padding: 1px 0;

        span {
            display: inline-block;
            align-items: center;
            line-height: 22px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;

            .non-editable {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
            }

            .editable {
                color: white;
            }

            &.site-name {
                margin-top: 4px;
            }

            &:last-child {
                color: #cccccc;
                font-size: 11px;
                line-height: 11px;
                height: 11px;
            }
        }

    }

    figure:not(:last-child),
    figure {
        margin: 0 10px 0 0;
    }

    .image {
        cursor: pointer;
        img {
            height: $placeLineHeight;
            width: $placeLineHeight;
        }
    }
}