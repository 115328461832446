.pageloader {
  padding-top: 0;
  text-align: center;
  width: 100%;
  &.is-success {
    &::after {
      display: none;
    }
    div.success-checkmark {
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      margin-top: -3em;
    }
  }
}
