.vis-bottom-nav {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 0;
	font-size: 0.9rem;
	width: 100%;
	background-color: transparent;
	transition: background-color 600ms ease-in-out;
	pointer-events: none;
	z-index: 4 !important;

	&.background {
		background-color: $box-background-color;
	}
	.level {
		align-items: flex-end;
	}
	.visualize-brand {
		padding: 10px;
	}
	.viewer-configurators {
		&.hidden {
			display: none;
			visibility: hidden;
		}
		position: fixed;
		right: 0;
		bottom: 0;
		background: $box-background-color;
		border-radius: 2px 0 0 0;
		padding: 4px 4px 4px 4px;
		> * {
			margin-right: 4px;
		}
	}
	> * {
		pointer-events: all;
	}
	> .level-right {
		background: $box-background-color;
	}
	&.background {
		background-color: $box-background-color;
		.level-left {
			padding: 5px;
			a {
				color: $primary;
				&:hover {
					color: $link-hover;
				}
			}
			.logo a,
			a {
				color: $primary;
			}
		}
	}
	.level-left {
		padding: 5px;
		a {
			color: white;
		}
		&:hover {
			background: $box-background-color;
			.logo a,
			a {
				color: $primary;
			}
		}
	}
}
