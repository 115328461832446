.settings-pane {
	background-color: transparent;
	transition: all 300ms;
	display: flex;
	max-width: 100%;
	flex: 1;
	overflow-y: auto;
	section {
		background: rgba($box-background-color, 0.2);
		flex: 1;
		border-radius: 0;
		padding: 10px;
		> .box {
			border-radius: 0;
			//min-height: 50%;
		}
	}

	.switch-container {
		display: flex;
		margin-top: 10px;
		.switch-item {
			margin-right: 10px;
		}
	}

	.tabs-container {
		max-height: 100%;
	}

	.tabs-container .box.admin-roles {
		flex: 0 0 auto;
	}

	.edit-role-form {
		//	max-width:1800px;
		.button-container {
			margin-top: 35px;
			display: flex;
			align-items: center;

			.button {
				margin-right: 10px;
			}

			.loading {
				display: inline-block;
			}
		}
	}

	.edit-role-form input {
		width: 400px;
		flex: 0 0 auto;
	}

	.edit-role-form textarea {
		width: 400px;
		flex: 0 0 auto;
		min-width: unset;
	}

	.edit-role-form .field-label {
		min-width: 200px;
	}

	.edit-role-form h6.title {
		font-size: 15px;
	}

	.edit-role-form .edit-role-form-general-field {
		display: flex;
		flex-order: row;
		justify-content: flex-start;
	}

	.edit-role-form .edit-role-form-general-label {
		font-size: 12px;
		font-weight: 700;
		min-width: 150px;
	}

	.edit-role-form .edit-role-form-membership {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
		max-width: 1300px;

		> div {
			flex: auto;
			margin-right: 30px;

		}
	}

	.edit-role-form .feature-permission-toggle-set {
		display: flex;
		flex-direction: row;
	}

	.feature-permission-toggle-container {
		height: 55px;
		width: 275px;
		display: flex;
		justify-content: center;
	}

	.feature-permission-toggle {
		width: 250px;
		background: unset;
	}

	.feature-permission-toggle .inherit {
		background: grey;
	}

	.feature-permission-toggle .allow {
		background: green;
	}

	.feature-permission-toggle .deny {
		background: indianred;
	}

	.edit-role-form .edit-role-form-section-header {
		font-size: 18px;
		font-weight: 700;
		margin-top: 25px;
		margin-bottom: 8px;
	}

	.edit-role-form .listbox-container {
		width: 95%;
		height: 300px;
		box-shadow: 2px 2px #888888;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		overflow-y: auto;
		overflow-x: hidden;
		margin-bottom: 5px;
		margin-right: 5px;
		border: 1px solid;
		padding: 5px;
	}

	.edit-role-form .listbox-item:hover {
		background: $primary;
		color: $white;
	}

	.edit-role-form .listbox-item.selected {
		background: $primary;
		color: $white;
	}

	.edit-role-form .dual-listbox-container {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.edit-role-form .dual-listbox-list-header {
		font-size: 12px;
		font-weight: 700;
	}

	.dual-listbox-list-container {
		flex-grow: 2;
		max-width: 600px;
	}

	.edit-role-form .dual-listbox-middle-container {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		flex-grow: 0;
		width: 60px;
		margin-right: 25px;

	}

	.tabs {
		margin-top: 25px;
		margin-bottom: 0px;
	}

	.tabs.is-toggle a {
		border-color: $primary;
		border-width: 2px;
		font-size: 1.25em;
		font-weight: 300;
	}

	.tabs.is-toggle li.is-active a {
		background: $primary;
	}

	.tabs.is-toggle li a {
		border-top-left-radius: 0.4em;
		border-top-right-radius: 0.4em;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.tabs-container {
		//min-height: 50%;
		display: flex;
		flex-direction: column;
	}

	.tabs-container .box {
		flex-grow: 1;
	}

	.list-container .roles-list-item {
		flex: 1 1 0;
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.list-container .roles-list-item.header {
		font-weight: 800;
	}

	.list-container {
		display: flex;
		flex-direction: column;
		max-width: 1600px;

		.list-empty-warning {
			flex: 1;
			display: flex;
			flex-direction: row;
			justify-content: center;
			font-size: 18px;
			margin: 10px 0;
		}

		.list-row {
			flex: 1;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			:not(.header) {
				cursor: pointer;
			}
		}

		.list-row:not(.header):hover {
			color: white;
			background: $primary;
		}

		.list-row-controls-panel {
			border: 1px solid $primary;
			padding: 10px;

			.controls-container {
				display: flex;
				flex-direction: row;

				.action {
					margin-right: 15px;
					cursor: pointer;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.list-row-selected {
			color: white;
			background: $primary;
		}
	}

	.list-container .users-list-item {
		flex: 1 1 0;	
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 10px;
		padding-bottom: 10px;

		&.sso-active {
			flex: 0 0 50px;	
		}
	}

	.list-container .users-list-item.header {
		font-weight: 800;
	}

	.create-user-container {
		.field {
			align-items: center;

			.role-select-container {
				min-width: 170px;
			}
		}

		.button-container {
			margin-top: 20px;
			display: flex;
			align-items: center;

			.button {
				margin-right: 10px;
			}

			.loading {
				display: inline-block;
			}
		}
	}
}

.edit-role-form-permissions-header-col0 {
	width: 275px;
	margin: 0;
	padding: 0;
	flex: 0 0 auto;
}

.role-permission-field {
	height: 450px;
	
	.table-container {
		max-width: 1120px;
		max-height: 410px;
		overflow: auto;
		position: relative;
	}
	table {
		table-layout: fixed;
		display: block;
		white-space: nowrap;

		tbody {
			display: block;
		}
		thead th {
			position: sticky;
			top: 0;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			z-index: 100;
			background-color: rgba(84, 84, 84, 1);
		}
		thead th:first-child {
			left: 0;
			z-index: 101;
		}
		tbody th {
			position: sticky;
			background-color: rgba(84, 84, 84, 1);
			z-index: 100;
			left: 0;
			display: inline-flex;
			justify-content: flex-start;
			align-items: center;
			padding-left: 15px;
			font-weight: 100;
		}
		tbody th.category-header {
			height: 25px;
			font-weight: bold;
			padding-left: 0px;

		}
		th, td {
			width: 275px;
			height: 55px;
			white-space:nowrap;

		}
	}
}