.search-bar {
	padding: 4px;
	display: flex;
	* {
		border-radius: 0;
	}
	.dropdown-trigger {
		.button {
			height: 100%;
		}
	}
	.control {
		flex: 1;
		.input {
		}
	}
}

.search-line {
	padding: 2px 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	&:hover {
		color: $primary;
	}
	&:nth-child(even) {
		background: rgba(255, 255, 255, 0.1);
	}
	.search-site-name {
		font-size: 0.8rem;
		color: rgba($text, 0.7);
	}
}
