@import "darkvariables";
@import "../../../src/assets/scss/ninjadojo-bulma/index.scss";
@import "darkoverrides";
@import "index";

*, :after, :before {
  box-sizing: inherit;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.input, .textarea {
  transition: all .2s ease;
  box-shadow: none;
  border-width: 1px;
  padding-left: 1em;
  padding-right: 1em;
}

.field:not(:last-child) {
  margin-bottom: 0.75rem;
}

.button, .control.has-icons-left .icon, .control.has-icons-right .icon, .input, .pagination-ellipsis, .pagination-link, .pagination-next, .pagination-previous, .select, .select select, .textarea {
  height: 2.5em;
}

.toggle-button {
  color: #fff !important;
}

.button.is-active, .button.is-focused, .button:active, .button:focus {
  box-shadow: none;
  //box-shadow: 0 0 0 2px rgb(0 0 0);
}

.pointgrab-button {
  height: 1.7em !important;
}

.point-grab-tabs {
  background-color: rgba(84, 84, 84, 0.7);
}

.measure-icons {
  height: 23px !important;
  width: 21px;
  margin: 3px;
  box-shadow: none;
  box-shadow: 0 0 0 2px rgba($primary, 0.5);
  border-color: #636363 !important;
  color: #dde4e6;
}

.measure-icon:hover {
  border-color: #00ff1d;
}

.input, .textarea {
  box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
  max-width: 100%;
  width: 96%;
}

.input, .select select, .textarea {
  background-color: rgba(99, 99, 99, .2);
  border-color: rgba(84, 84, 84, .2);
  border-radius: 0.4em;
  color: #ecf0f1;
}

.button, .file-cta, .file-name, .input, .pagination-ellipsis, .pagination-link, .pagination-next, .pagination-previous, .select select, .textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.4em;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  position: relative;
  vertical-align: top;
}

img {
  height: auto;
  max-width: 100%;
}

blockquote, body, dd, dl, dt, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, html, iframe, legend, li, ol, p, pre, textarea, ul {
  margin: 0;
  padding: 0;
}

.pagination-link, .pagination-next, .pagination-previous, .panel-block, .panel-heading, .panel-tabs {
  border-width: 1px;
}

embed, iframe, img, object, video {
  height: auto;
  max-width: 100%;
}

#minimap {
  cursor: pointer !important;
}

.visualize {
  .sidebar-content {
    .app-content {
      .sidebar-content {
        background: rgba(0, 0, 0, 0.8);
      }
    }
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #000;
  border-radius: 10px;
}

.button, .file-cta, .file-name, .input, .pagination-ellipsis, .pagination-link, .pagination-next, .pagination-previous, .select select, .textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.4em;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  position: relative;
  vertical-align: top;
}

.sidebar.sites-sidebar .site-selector .ss-site {
  width: 400px;
  cursor: pointer;
  border: 2px solid rgba(250, 162, 27, .5);
}

.box {
  border: 1px solid hsla(0, 0%, 52.9%, .7);
}

.block:not(:last-child), .box:not(:last-child), .breadcrumb:not(:last-child), .content:not(:last-child), .highlight:not(:last-child), .level:not(:last-child), .list:not(:last-child), .message:not(:last-child), .notification:not(:last-child), .progress:not(:last-child), .subtitle:not(:last-child), .table-container:not(:last-child), .table:not(:last-child), .tabs:not(:last-child), .title:not(:last-child) {
  margin-bottom: 1.5rem;
}

.box {
  background-color: rgba(84, 84, 84, .7);
  border-radius: 8px;
  box-shadow: none;
  color: #ecf0f1;
  display: block;
  padding: 1.25rem;
}

body .button.is-text {
  text-decoration: none;
  font-weight: 400;
}

.media {
  align-items: flex-start;
  display: flex;
  text-align: left;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

.card-header-title {
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.card-header-title {
  color: #dde4e6;
  display: flex;
  flex-grow: 1;
  font-weight: 700;
  padding: 0.75rem;
}

.button.is-disabled {
  opacity: .5;
}

.measure-actions span {
  margin: 0 0 0 5px;
}

.button, .control.has-icons-left .icon, .control.has-icons-right .icon, .input, .pagination-ellipsis, .pagination-link, .pagination-next, .pagination-previous, .select, .select select, .textarea {
  height: 2.5em;
}

.title.is-4 {
  font-size: 1.5rem;
}

.title.is-6 {
  font-size: 1rem;
}

.block:not(:last-child), .box:not(:last-child), .breadcrumb:not(:last-child), .content:not(:last-child), .highlight:not(:last-child), .level:not(:last-child), .list:not(:last-child), .message:not(:last-child), .notification:not(:last-child), .progress:not(:last-child), .subtitle:not(:last-child), .table-container:not(:last-child), .table:not(:last-child), .tabs:not(:last-child), .title:not(:last-child) {
  margin-bottom: 1.5rem;
}

.title {
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.125;
}

.button, .file-cta, .file-name, .input, .pagination-ellipsis, .pagination-link, .pagination-next, .pagination-previous, .select select, .textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.4em;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  position: relative;
  vertical-align: top;
}

.subtitle, .title {
  word-break: break-word;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: 400;
}

blockquote, body, dd, dl, dt, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, html, iframe, legend, li, ol, p, pre, textarea, ul {
  margin: 0;
  padding: 0;
}

.viewer-controls > * {
  pointer-events: all;
}

.viewer-controls {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  padding: 8px 8px 100px;
  width: 410px;
  height: 100%;
  background: transparent;
  flex-direction: column;
  pointer-events: none;
  z-index: 5 !important;
}

.viewer-controls .sidebar-content nav.tools > * {
  min-height: 30px;
  text-align: center;
  align-items: center;
  flex: 1;
  border-radius: 0;
}

.label:not(:last-child) {
  margin-bottom: 0.5em;
}

.label {
  color: #dbdee0;
}

.label {
  color: #2b2b2b;
  display: block;
  font-size: 1rem;
  font-weight: 700;
}

.button.is-text {
  background-color: transparent;
  border-color: transparent;
  color: #ecf0f1;
  text-decoration: underline;
  width: 80px;
  border-radius: 0px;
}

.button.is-text.is-active, .button.is-text:hover {
  color: #faa21b;
  border: 1px solid #faa21b;
}

.button.is-text.is-active, .button.is-text:active {
  background-color: #1e1e1e;
  color: #dde4e6;
}

.button.is-active, .button:active {
  border-color: #636363;
  color: #dde4e6;
}

.button.is-primary {
  background-color: #faa21b;
  border-color: transparent;
  color: #fff;
}

.button.is-success {
  background-color: #2ecc71;
  border-color: transparent;
  color: #fff;
}


.button {
  background-color: #2b2b2b;
  border-color: #515151;
  color: #faa21b;
  cursor: pointer;
  justify-content: center !important;
  padding: calc(0.375em - 1px) 0.75em;
  text-align: center;
  white-space: nowrap;
}

.button {
  transition: all .3s ease;
  border-width: 1px;
  color: #fff;
  background-color: #2b2b2b;
  border-color: #515151;
  //color: #faa21b;
  cursor: pointer;
  border-color: #636363;
  justify-content: center;
  padding: calc(0.375em - 1px) 0.75em;
  text-align: center;
  white-space: nowrap;
}

.button, .file-cta, .file-name, .input, .pagination-ellipsis, .pagination-link, .pagination-next, .pagination-previous, .select select, .textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border-color: #636363;
  border: 1px solid transparent;
  border-radius: 0.4em;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  position: relative;
  vertical-align: top;
}

.places-tools-container #places-filter-site-container {
  width: 50px;
  padding-top: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.places-tools-container #places-filter-site-container label {
  margin: 0;
  padding: 0 0 0 28px;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

.is-checkradio[type=checkbox] + label, .is-checkradio[type=radio] + label {
  position: relative;
  display: initial;
  cursor: pointer;
  vertical-align: middle;
  margin: 0.5em;
  padding: 0.2rem 0.5rem 0.2rem 0;
  border-radius: 0.4em;
}

.is-checkradio[type=checkbox] + label:first-of-type, .is-checkradio[type=radio] + label:first-of-type {
  margin-left: 0;
}

.is-checkradio[type=checkbox] + label {
  font-size: 1rem;
  padding-left: 2rem;
}

.is-checkradio[type=checkbox]:checked + label:before, .is-checkradio[type=radio]:checked + label:before {
  animation-name: none;
}

.is-checkradio[type=checkbox]:checked + label:before, .is-checkradio[type=radio]:checked + label:before {
  border: 0.1rem solid #dbdee0;
}

.is-checkradio[type=checkbox] + label:before {
  width: 1.3rem;
  height: 1.3rem;
}

.is-checkradio[type=checkbox] + label:before {
  border-radius: 0.4em;
}

.is-checkradio[type=checkbox] + label:before, .is-checkradio[type=radio] + label:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  border: 0.1rem solid #dbdee0;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

.input, .textarea {
  transition: all .2s ease;
  box-shadow: none;
  border-width: 1px;
  padding-left: 1em;
  padding-right: 1em;
}

.input, .select select, .textarea {
  background-color: rgba(99, 99, 99, .2);
  border-color: rgba(84, 84, 84, .2);
  border-radius: 0.4em;
  color: #ecf0f1;
}

.breadcrumb, .button, .delete, .file, .is-unselectable, .modal-close, .pagination-ellipsis, .pagination-link, .pagination-next, .pagination-previous, .tabs {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-color: #636363;
}


.card-footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #919191;
  align-items: stretch;
}

.card-footer button {
  border-radius: 0;
  background-color: #2b2b2b;
  border-color: #515151;
  color: #fff;
  cursor: pointer;
  justify-content: center;
  padding: calc(0.375em - 1px) 0.5em;
  text-align: center;
  white-space: nowrap;
  height: 2em;
}

.card-footer button:hover {
  border-color: #dde4e6;
}

.angle-toggle {
  margin-left: auto;
  margin-right: auto;
}

.modal-card-foot, .modal-card-head {
  border-color: #636363;
}

.modal-card-head {
  border-bottom: 1px solid #919191;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.modal-card-foot, .modal-card-head {
  align-items: center;
  background-color: #2b2b2b;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.modal-card {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  -ms-overflow-y: visible;
}

.modal-card, .modal-content {
  margin: 0 auto;
  max-height: calc(100vh - 40px);
  width: 640px;
}

.modal-card, .modal-content {
  margin: 0 20px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  width: 100%;
}

.modal.is-active {
  z-index: 100;
}

.modal.is-active {
  display: flex !important;
}

.modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center !important;
  overflow: hidden;
  position: fixed !important;
  z-index: 40;
}

.modal-card-body input {
  background-color: rgba(99, 99, 99, .2);
  border-color: rgba(84, 84, 84, .2);
  border-radius: 0.4em;
  color: #ecf0f1;
}

.button.is-danger {
  background-color: #e74c3c;
  color: #fff;
}

#app > div.modal.is-active > div.modal-card > section > div.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root > div > input:focus {
  border-color: red !important;
}


#app > div.modal.is-active > div.modal-card > section > div.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root > div > input {
  background-color: rgba(99, 99, 99, .2);
  border-color: rgba(84, 84, 84, .2);
  border-radius: 0.4em;
  color: #ecf0f1;
  box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
  max-width: 100%;
  width: 100%;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.4em;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.25em;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  position: relative;
  vertical-align: top;
  transition: all .2s ease;
  box-shadow: none;
  border-width: 1px;
  padding-left: 1em;
  padding-right: 1em;
  height: 2.5em;
}

.disclaimer {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.modal-background {
  background: linear-gradient(135deg, #f48461 0%, #fbbb5b 50%, #f48461 100%)
}

.modal-card, .modal-content {
  margin: 0 auto;
  max-height: calc(100vh - 40px);
  width: 640px;
}

.modal-card-body {
  background-color: #2b2b2b;
}

.modal-edit-field {
  width: 100%
}

.modal-edit-field:focus {
  outline: solid 10px red !important;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 20px;
}

.hero-video, .image.is-1by1 .has-ratio, .image.is-1by1 img, .image.is-1by2 .has-ratio, .image.is-1by2 img, .image.is-1by3 .has-ratio, .image.is-1by3 img, .image.is-2by1 .has-ratio, .image.is-2by1 img, .image.is-2by3 .has-ratio, .image.is-2by3 img, .image.is-3by1 .has-ratio, .image.is-3by1 img, .image.is-3by2 .has-ratio, .image.is-3by2 img, .image.is-3by4 .has-ratio, .image.is-3by4 img, .image.is-3by5 .has-ratio, .image.is-3by5 img, .image.is-4by3 .has-ratio, .image.is-4by3 img, .image.is-4by5 .has-ratio, .image.is-4by5 img, .image.is-5by3 .has-ratio, .image.is-5by3 img, .image.is-5by4 .has-ratio, .image.is-5by4 img, .image.is-9by16 .has-ratio, .image.is-9by16 img, .image.is-16by9 .has-ratio, .image.is-16by9 img, .image.is-square .has-ratio, .image.is-square img, .is-overlay, .modal, .modal-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

.sidebar .sidebar-inner .panel-heading {
  margin-top: 5px;
  border: none;
  color: #faa21b;
}

.sidebar .sidebar-inner .panel-block {
  border: none;
  color: #ecf0f1;
}

a.panel-block, label.panel-block {
  cursor: pointer;
}

.pagination-link, .pagination-next, .pagination-previous, .panel-block, .panel-heading, .panel-tabs {
  border-width: 1px;
}

.panel:not(:last-child) {
  margin-bottom: 1.5rem;
}

.panel {
  font-size: 1rem;
}

.panel-icon {
  display: inline-block;
  font-size: 14px;
  height: 1em;
  line-height: 1em;
  text-align: center;
  vertical-align: top;
  width: 1em;
  color: #fff;
  margin-right: 0.75em;
}

.panel-block {
  align-items: center;
  color: #dde4e6;
  display: flex;
  justify-content: flex-start;
  padding: 0.5em 0.75em;
}

.panel-block, .panel-heading, .panel-tabs {
  border-bottom: 1px solid #919191;
  border-left: 1px solid #919191;
  border-right: 1px solid #919191;
}

.panel-block:first-child, .panel-heading:first-child, .panel-tabs:first-child {
  border-top-width: 1px;
}

.panel-block:first-child, .panel-heading:first-child, .panel-tabs:first-child {
  border-top: 1px solid #919191;
}

.panel-heading {
  border-radius: 2px 2px 0 0;
}

.panel-heading {
  font-weight: 700;
}

.pagination-link, .pagination-next, .pagination-previous, .panel-block, .panel-heading, .panel-tabs {
  border-width: 1px;
}

.panel-block {
  align-items: center;
  color: #dde4e6;
  display: flex;
  justify-content: flex-start;
  padding: 0.5em 0.75em;
}

.panel-block, .panel-heading, .panel-tabs {
  border-bottom: 1px solid #919191;
  border-left: 1px solid #919191;
  border-right: 1px solid #919191;
}

.pagination-link, .pagination-next, .pagination-previous, .panel-block, .panel-heading, .panel-tabs {
  border-width: 1px;
}


.panel-heading {
  background-color: #2b2b2b;
  border-radius: 0.4em 0.4em 0 0;
  color: #dde4e6;
  font-size: 1.25em;
  font-weight: 300;
  line-height: 1.25;
  padding: 0.5em 0.75em;
}

.panel-block, .panel-heading, .panel-tabs {
  border-bottom: 1px solid #919191;
  border-left: 1px solid #919191;
  border-right: 1px solid #919191;
}

.metadata-tabs .tab-container span.selected {
  background-color: hsla(0, 0%, 100%, .3);
}

.metadata-tabs .tab-container span {
  padding: 4px 10px;
  border-style: solid;
  border-width: 1px 1px 0;
  margin: 0 10px 0 0;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

.content table th:not([align]) {
  text-align: left;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.metadata-tabs .data-container tr:last-child td {
  border-bottom-width: 1px;
}

.content table tbody tr:last-child td, .content table tbody tr:last-child th {
  border-bottom-width: 0;
}

.metadata-tabs .data-container td, .metadata-tabs .data-container th {
  min-width: 90px;
  padding: 0 10px 0 0;
}

.content table td, .content table th {
  border: solid #919191;
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
}

table td, table th {
  vertical-align: top;
}

td, th {
  padding: 0;
}

.metadata-tabs .data-container td, .metadata-tabs .data-container th {
  min-width: 90px;
  padding: 0 10px 0 0;
}

.content table td, .content table th {
  border: solid #919191;
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
}

a {
  cursor: pointer;
  text-decoration: none;
}

a, body {
  color: #ecf0f1;
}

a.panel-block, label.panel-block {
  cursor: pointer;
}


body {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}

#navigationDiv {
  .compass-outer-ring-background {
    border: 12px solid rgba(255, 255, 255, 0.5);
  }

  .compass-outer-ring {
    svg {
      fill: rgba(0, 0, 0, 0.6);
    }

    &:hover {
      svg {
        fill: $primary;
      }
    }
  }

  .compass-gyro-background {
    background-color: rgba(255, 255, 255, 0.4);
    border: 1px solid rgba(0, 0, 0, 0.6);
  }

  .compass-gyro {
    fill: rgba(0, 0, 0, 0.6);

    &:hover {
      fill: $primary;
    }
  }
}

.nearby-image .nearby-actions .button {
  font-size: 22px;
  width: 45px;
  height: 45px;
}

.disclaimer-footer {
  margin-top: 30px;

  button {
    background-color: $primary;
    width: 100%;
    font-size: 18px;
  }
}

.pointgrab-controls {
  width: 40px;
}

.icon {
  float: left;
  width: 14px;
}
